.dygraph-legend {
  position: absolute;
  font-size: 14px;
  z-index: 10;
  padding: 0.7rem 1rem;
  background: white;
  line-height: normal;
  text-align: left;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 2px 4px -2px;
}

.dygraph-legend-line {
  display: inline-block;
  position: relative;
  padding-left: 13px;
  margin-right: 8px;
  border-bottom-width: 13px;
  border-bottom-style: solid;
}

.dygraph-legend-row {
  display: flex;
  align-items: center;
  margin-top: 6px;
  flex-wrap: nowrap;
}

.dygraph-axis-label {
  /* position: absolute; */
  font-size: 12px;
  z-index: 10;
  line-height: normal;
  overflow: hidden;
  color: black;
  opacity: 0.8;
}

.dygraph-y2label {
  position: absolute;
  width: 100%;
  top: -1rem;
}

.dygraph-ylabel {
  position: absolute;
  width: 100%;
  top: -1rem;
}
