.react-pdf__Document {
  height: 66vh;
  overflow: auto;
  border: 16px solid #707070;
  background-color: #707070;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.react-pdf__Page + .react-pdf__Page {
  margin-top: 16px;
}
